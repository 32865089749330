import roundedCheckSrc, {
  ReactComponent as RoundedCheck,
} from './rounded-check.svg'
import roundedOutlineCrossSrc, {
  ReactComponent as RoundedOutlineCross,
} from './rounded-outline-cross.svg'
import infoSrc, { ReactComponent as Info } from './info.svg'
import infoRegularSrc, {
  ReactComponent as InfoRegular,
} from './info-regular.svg'
import infoImportantSrc, {
  ReactComponent as InfoImportant,
} from './info-important.svg'
import roundedCloseSrc, {
  ReactComponent as RoundedClose,
} from './close-circle-btn.svg'
import zezamLogoSrc, {
  ReactComponent as ZezamLogo,
} from './zezam-logo-icon.svg'
import closeSrc, { ReactComponent as Close } from './close.svg'
import addSrc, { ReactComponent as Add } from './icon-add.svg'
import removeSrc, { ReactComponent as Remove } from './icon-remove.svg'
import imageProfilePlaceholderSrc, {
  ReactComponent as ImageProfilePlaceholder,
} from './image-profile.svg'
import uploadImageSrc, {
  ReactComponent as UploadImage,
} from './upload-image-icon.svg'
import audioSrc, { ReactComponent as Audio } from './audio.svg'
import chevronSrc, { ReactComponent as Chevron } from './chevron.svg'
import rocketSrc, { ReactComponent as Rocket } from './rocket.svg'
import rocketActiveSrc, {
  ReactComponent as RocketActive,
} from './rocket-active.svg'
import myPageSrc, { ReactComponent as MyPage } from './my-page.svg'
import myPageActiveSrc, {
  ReactComponent as MyPageActive,
} from './my-page-active.svg'
import shopSrc, { ReactComponent as Shop } from './shop.svg'
import shopActiveSrc, { ReactComponent as ShopActive } from './shop-active.svg'
import menuSrc, { ReactComponent as Menu } from './menu.svg'
import logoFullSrc, { ReactComponent as LogoFull } from './logo-full.svg'
import paintSrc, { ReactComponent as Paint } from './paint.svg'
import paintActiveSrc, {
  ReactComponent as PaintActive,
} from './paint-active.svg'
import settingsSrc, { ReactComponent as Settings } from './settings.svg'
import settingsActiveSrc, {
  ReactComponent as SettingsActive,
} from './settings-active.svg'
import amazonMusicMonoSrc, {
  ReactComponent as AmazonMusicMono,
} from './socials/mono/amazon-music.svg'
import appleMusicMonoSrc, {
  ReactComponent as AppleMusicMono,
} from './socials/mono/apple-music.svg'
import bandcampMonoSrc, {
  ReactComponent as BandcampMono,
} from './socials/mono/bandcamp.svg'
import beatportMonoSrc, {
  ReactComponent as BeatportMono,
} from './socials/mono/beatport.svg'
import clubhouseMonoSrc, {
  ReactComponent as ClubhouseMono,
} from './socials/mono/clubhouse.svg'
import deezerMonoSrc, {
  ReactComponent as DeezerMono,
} from './socials/mono/deezer.svg'
import discordMonoSrc, {
  ReactComponent as DiscordMono,
} from './socials/mono/discord.svg'
import emailMonoSrc, {
  ReactComponent as EmailMono,
} from './socials/mono/email.svg'
import facebookMonoSrc, {
  ReactComponent as FacebookMono,
} from './socials/mono/facebook.svg'
import githubMonoSrc, {
  ReactComponent as GithubMono,
} from './socials/mono/github.svg'
import gitlabMonoSrc, {
  ReactComponent as GitlabMono,
} from './socials/mono/gitlab.svg'
import instagramMonoSrc, {
  ReactComponent as InstagramMono,
} from './socials/mono/instagram.svg'
import linkedinMonoSrc, {
  ReactComponent as LinkedinMono,
} from './socials/mono/linkedin.svg'
import patreonMonoSrc, {
  ReactComponent as PatreonMono,
} from './socials/mono/patreon.svg'
import paypalMonoSrc, {
  ReactComponent as PaypalMono,
} from './socials/mono/paypal.svg'
import pinterestMonoSrc, {
  ReactComponent as PinterestMono,
} from './socials/mono/pinterest.svg'
import snapchatMonoSrc, {
  ReactComponent as SnapchatMono,
} from './socials/mono/snapchat.svg'
import soundcloudMonoSrc, {
  ReactComponent as SoundcloudMono,
} from './socials/mono/soundcloud.svg'
import spotifyMonoSrc, {
  ReactComponent as SpotifyMono,
} from './socials/mono/spotify.svg'
import telegramMonoSrc, {
  ReactComponent as TelegramMono,
} from './socials/mono/telegram.svg'
import tikTokMonoSrc, {
  ReactComponent as TikTokMono,
} from './socials/mono/tiktok.svg'
import twitchMonoSrc, {
  ReactComponent as TwitchMono,
} from './socials/mono/twitch.svg'
import twitterMonoSrc, {
  ReactComponent as TwitterMono,
} from './socials/mono/twitter.svg'
import vimeoMonoSrc, {
  ReactComponent as VimeoMono,
} from './socials/mono/vimeo.svg'
import vKontakteMonoSrc, {
  ReactComponent as VKontakteMono,
} from './socials/mono/vkontakte.svg'
import websiteMonoSrc, {
  ReactComponent as WebsiteMono,
} from './socials/mono/website.svg'
import whatsappMonoSrc, {
  ReactComponent as WhatsappMono,
} from './socials/mono/whatsapp.svg'
import youtubeMonoSrc, {
  ReactComponent as YoutubeMono,
} from './socials/mono/youtube.svg'
import tumblrMonoSrc, {
  ReactComponent as TumblrMono,
} from './socials/mono/tumblr.svg'
import zezamMonoSrc, { ReactComponent as ZezamMono } from './zezam-mono.svg'
import AmazonMusicSrc, {
  ReactComponent as AmazonMusic,
} from './socials/colored/amazon-music.svg'
import AppleMusicSrc, {
  ReactComponent as AppleMusic,
} from './socials/colored/apple-music.svg'
import BandcampSrc, {
  ReactComponent as Bandcamp,
} from './socials/colored/bandcamp.svg'
import BeatportSrc, {
  ReactComponent as Beatport,
} from './socials/colored/beatport.svg'
import ClubHouseSrc, {
  ReactComponent as ClubHouse,
} from './socials/colored/clubhouse.svg'
import DeezerSrc, {
  ReactComponent as Deezer,
} from './socials/colored/deezer.svg'
import DiscordSrc, {
  ReactComponent as Discord,
} from './socials/colored/discord.svg'
import EmailSrc, { ReactComponent as Email } from './socials/colored/email.svg'
import FacebookSrc, {
  ReactComponent as Facebook,
} from './socials/colored/facebook.svg'
import GitHubSrc, {
  ReactComponent as GitHub,
} from './socials/colored/github.svg'
import GitLabSrc, {
  ReactComponent as GitLab,
} from './socials/colored/gitlab.svg'
import InstagramSrc, {
  ReactComponent as Instagram,
} from './socials/colored/instagram.svg'
import LinkedInSrc, {
  ReactComponent as LinkedIn,
} from './socials/colored/linkedin.svg'
import PatreonSrc, {
  ReactComponent as Patreon,
} from './socials/colored/patreon.svg'
import PayPalSrc, {
  ReactComponent as PayPal,
} from './socials/colored/paypal.svg'
import PinterestSrc, {
  ReactComponent as Pinterest,
} from './socials/colored/pinterest.svg'
import SnapchatSrc, {
  ReactComponent as Snapchat,
} from './socials/colored/snapchat.svg'
import SoundcloudSrc, {
  ReactComponent as Soundcloud,
} from './socials/colored/soundcloud.svg'
import SpotifySrc, {
  ReactComponent as Spotify,
} from './socials/colored/spotify.svg'
import TelegramSrc, {
  ReactComponent as Telegram,
} from './socials/colored/telegram.svg'
import TikTokSrc, {
  ReactComponent as TikTok,
} from './socials/colored/tiktok.svg'
import TwitchSrc, {
  ReactComponent as Twitch,
} from './socials/colored/twitch.svg'
import TwitterSrc, {
  ReactComponent as Twitter,
} from './socials/colored/twitter.svg'
import VkontakteSrc, {
  ReactComponent as Vkontakte,
} from './socials/colored/vkontakte.svg'
import VimeoSrc, { ReactComponent as Vimeo } from './socials/colored/vimeo.svg'
import WebsiteSrc, {
  ReactComponent as Website,
} from './socials/colored/website.svg'
import WhatsAppSrc, {
  ReactComponent as WhatsApp,
} from './socials/colored/whatsapp.svg'
import YouTubeSrc, {
  ReactComponent as YouTube,
} from './socials/colored/youtube.svg'
import TumblrSrc, {
  ReactComponent as Tumblr,
} from './socials/colored/tumblr.svg'
import ApplePodcastsSrc, {
  ReactComponent as ApplePodcasts,
} from './socials/colored/apple-podcasts.svg'
import ApplePodcastsMonoSrc, {
  ReactComponent as ApplePodcastsMono,
} from './socials/mono/apple-podcasts.svg'
import analyticsSrc, { ReactComponent as Analytics } from './analytics.svg'
import analyticsActiveSrc, {
  ReactComponent as AnalyticsActive,
} from './analytics-active.svg'
import customLinkSrc, { ReactComponent as CustomLink } from './custom-link.svg'
import peopleGroupSrc, {
  ReactComponent as PeopleGroup,
} from './people-group.svg'
import playlistSrc, { ReactComponent as Playlist } from './playlist.svg'
import videoLinkSrc, { ReactComponent as Video } from './video.svg'
import linkSrc, { ReactComponent as Link } from './link.svg'
import calendarSrc, { ReactComponent as Calendar } from './calendar.svg'
import peopleSrc, { ReactComponent as People } from './people.svg'
import pendingSrc, { ReactComponent as Pending } from './pending.svg'
import magicWandSrc, { ReactComponent as MagicWand } from './magic-wand.svg'
import sparklesSrc, { ReactComponent as Sparkles } from './sparkles.svg'
import sparkMonoSrc, { ReactComponent as SparkMono } from './spark-mono.svg'
import eyesSrc, { ReactComponent as Eyes } from './eyes.svg'
import arrowBackSrc, { ReactComponent as ArrowBack } from './arrow-back.svg'
import plusSrc, { ReactComponent as Plus } from './plus.svg'
import plusCircleSrc, { ReactComponent as PlusCircle } from './plus-circle.svg'
import uploadVideoSrc, {
  ReactComponent as UploadVideo,
} from './upload-video.svg'
import passwordShowSrc, {
  ReactComponent as PasswordShow,
} from './password-show.svg'
import passwordHideSrc, {
  ReactComponent as PasswordHide,
} from './password-hide.svg'
import zezamSpinnerSrc, {
  ReactComponent as ZezamSpinner,
} from './zezam-spinner.svg'
import textSrc, { ReactComponent as Text } from './text.svg'
import infoGrayScaleSrc, {
  ReactComponent as InfoGrayScale,
} from './info-gray-scale.svg'
import deleteBinSrc, { ReactComponent as DeleteBin } from './delete-bin.svg'
import messageSrc, { ReactComponent as Message } from './message.svg'
import removeCircleBlackSrc, {
  ReactComponent as RemoveCircleBlack,
} from './remove-black.svg'
import removeCircleWhiteSrc, {
  ReactComponent as RemoveCircleWhite,
} from './remove-white.svg'
import arrowRightSrc, { ReactComponent as ArrowRight } from './arrow-right.svg'
import dragDotsSrc, { ReactComponent as DragDots } from './drag-dots.svg'
import editPenSrc, { ReactComponent as EditPen } from './edit-pen.svg'
import linkExternalSrc, {
  ReactComponent as LinkExternal,
} from './link-external.svg'
import messengerSrc, { ReactComponent as Messenger } from './messenger.svg'
import downloadSrc, { ReactComponent as Download } from './download.svg'
import priceLabelSrc, { ReactComponent as PriceLabel } from './price-label.svg'
import locationPinSrc, {
  ReactComponent as LocationPin,
} from './location-pin.svg'
import timeSrc, { ReactComponent as Time } from './time.svg'
import locationLivestreamSrc, {
  ReactComponent as LocationLivestream,
} from './location-livestream.svg'
import redirectSrc, { ReactComponent as Redirect } from './redirect.svg'
import bgStyleSolidSrc, {
  ReactComponent as BgStyleSolid,
} from './background_style_solid.svg'
import bgStyleGradientSrc, {
  ReactComponent as BgStyleGradient,
} from './background_style_gradient.svg'
import bgStyleCoverSrc, {
  ReactComponent as BgStyleCover,
} from './background_style_cover.svg'
import bgStyleHeaderSrc, {
  ReactComponent as BgStyleHeader,
} from './background_style_header.svg'
import bgEffectNoneSrc, {
  ReactComponent as BgEffectNone,
} from './background_effect_none.svg'
import bgEffectFadedDarkSrc, {
  ReactComponent as BgEffectFadedDark,
} from './background_effect_faded_dark.svg'
import bgEffectFadedLightSrc, {
  ReactComponent as BgEffectFadedLight,
} from './background_effect_faded_light.svg'
import bgEffectBlurredDarkSrc, {
  ReactComponent as BgEffectBlurredDark,
} from './background_effect_blurred_dark.svg'
import bgEffectBlurredLightSrc, {
  ReactComponent as BgEffectBlurredLight,
} from './background_effect_blurred_light.svg'
import giftSrc, { ReactComponent as Gift } from './gift.svg'
import referralsSrc, { ReactComponent as Referrals } from './referrals.svg'
import referralsActiveSrc, {
  ReactComponent as ReferralsActive,
} from './referrals-active.svg'
import undoSrc, { ReactComponent as Undo } from './undo.svg'
import appleSrc, { ReactComponent as Apple } from './apple.svg'
import googleSrc, { ReactComponent as Google } from './google.svg'
import affiliateSrc, { ReactComponent as Affiliate } from './affiliate.svg'
import gridSrc, { ReactComponent as Grid } from './grid.svg'
import dollarCircleSrc, {
  ReactComponent as DollarCircle,
} from './dollar-circle.svg'
import singleProductSrc, {
  ReactComponent as SingleProduct,
} from './single-product.svg'
import forwardSrc, { ReactComponent as Forward } from './forward.svg'
import plainCheckSrc, { ReactComponent as PlainCheck } from './plain-check.svg'
import brandCollaborationsSrc, {
  ReactComponent as BrandCollaborations,
} from './brand-collaborations.svg'
import brandCollaborationsActiveSrc, {
  ReactComponent as BrandCollaborationsActive,
} from './brand-collaborations-active.svg'
import searchSrc, { ReactComponent as Search } from './search.svg'
import logoutSrc, { ReactComponent as Logout } from './logout.svg'
import shareSrc, { ReactComponent as Share } from './share.svg'
import metapicLogoSrc, {
  ReactComponent as MetapicLogo,
} from './metapic-logo.svg'
import zezamLogoOldSrc, {
  ReactComponent as ZezamLogoOld,
} from './zezam-logo.svg'
import productImagePlaceholderSrc, {
  ReactComponent as ProductImagePlaceholder,
} from './product-placeholder.svg'
import buttonAddSrc, { ReactComponent as ButtonAdd } from './button-add.svg'
import buttonCheckedSrc, {
  ReactComponent as ButtonChecked,
} from './button-checked.svg'
import buttonDeleteSrc, {
  ReactComponent as ButtonDelete,
} from './button-delete.svg'
import tapGestureSrc, { ReactComponent as TapGesture } from './tap-gesture.svg'
import phoneSrc, { ReactComponent as Phone } from './phone.svg'
import copySrc, { ReactComponent as Copy } from './copy.svg'
import commissionPerClickSrc, {
  ReactComponent as CommissionPerClick,
} from './commission-type.svg'
import convertSrc, { ReactComponent as Convert } from './convert.svg'
import plainRemoveSrc, { ReactComponent as PlainRemove } from './remove.svg'
import ratingsSrc, { ReactComponent as Ratings } from './ratings.svg'
import moreSrc, { ReactComponent as More } from './more.svg'
import expandableSrc, { ReactComponent as Expandable } from './expandable.svg'
import nonExpandableMultiItemsSrc, {
  ReactComponent as NonExpandableMultiItems,
} from './non-expandable-multi-items.svg'
import nonExpandableSingleItemSrc, {
  ReactComponent as NonExpandableSingleItem,
} from './non-expandable-single-item.svg'
import previewEyeSrc, { ReactComponent as PreviewEye } from './preview-eye.svg'
import homeSrc, { ReactComponent as Home } from './home.svg'
import homeActiveSrc, { ReactComponent as HomeActive } from './home-active.svg'
import tiktokBlackSrc, {
  ReactComponent as TikTokBlack,
} from './tiktok-black.svg'
import creditCardSrc, { ReactComponent as CreditCard } from './money.svg'
import languageSrc, { ReactComponent as Language } from './language.svg'
import moneyRoundSrc, { ReactComponent as MoneyRound } from './money-round.svg'
import chartSrc, { ReactComponent as Chart } from './chart.svg'

const IconAssets = {
  RoundedCheck,
  RoundedOutlineCross,
  InfoRegular,
  InfoImportant,
  RoundedClose,
  ZezamLogo,
  ZezamLogoOld,
  Close,
  Add,
  Remove,
  ImageProfilePlaceholder,
  UploadImage,
  Audio,
  Chevron,
  Rocket,
  RocketActive,
  MyPage,
  MyPageActive,
  Shop,
  ShopActive,
  Menu,
  LogoFull,
  Paint,
  PaintActive,
  Settings,
  SettingsActive,
  AmazonMusic,
  AppleMusic,
  Bandcamp,
  Beatport,
  ClubHouse,
  Deezer,
  Discord,
  Email,
  Facebook,
  GitHub,
  GitLab,
  Instagram,
  LinkedIn,
  Patreon,
  PayPal,
  Pinterest,
  Snapchat,
  Soundcloud,
  Spotify,
  Telegram,
  TikTok,
  Twitch,
  Twitter,
  Vkontakte,
  Vimeo,
  Website,
  WhatsApp,
  YouTube,
  Tumblr,
  ApplePodcasts,
  Messenger,
  ApplePodcastsMono,
  AmazonMusicMono,
  AppleMusicMono,
  BandcampMono,
  BeatportMono,
  ClubhouseMono,
  DeezerMono,
  DiscordMono,
  EmailMono,
  FacebookMono,
  GithubMono,
  GitlabMono,
  InstagramMono,
  LinkedinMono,
  PatreonMono,
  PaypalMono,
  PinterestMono,
  SnapchatMono,
  SoundcloudMono,
  SpotifyMono,
  TelegramMono,
  TikTokMono,
  TwitchMono,
  TwitterMono,
  VimeoMono,
  VKontakteMono,
  WebsiteMono,
  WhatsappMono,
  YoutubeMono,
  TumblrMono,
  ZezamMono,
  Analytics,
  AnalyticsActive,
  Calendar,
  People,
  Playlist,
  CustomLink,
  PeopleGroup,
  Video,
  Link,
  Pending,
  MagicWand,
  Sparkles,
  SparkMono,
  Eyes,
  ArrowBack,
  Plus,
  PlusCircle,
  UploadVideo,
  PasswordHide,
  PasswordShow,
  ZezamSpinner,
  Text,
  Info,
  InfoGrayScale,
  DeleteBin,
  RemoveCircleBlack,
  RemoveCircleWhite,
  Message,
  ArrowRight,
  DragDots,
  EditPen,
  LinkExternal,
  Download,
  PriceLabel,
  LocationPin,
  Time,
  LocationLivestream,
  Redirect,
  BgStyleCover,
  BgStyleGradient,
  BgStyleSolid,
  BgStyleHeader,
  BgEffectNone,
  BgEffectFadedDark,
  BgEffectFadedLight,
  BgEffectBlurredDark,
  BgEffectBlurredLight,
  Gift,
  Referrals,
  ReferralsActive,
  Undo,
  Apple,
  Google,
  Affiliate,
  Grid,
  DollarCircle,
  SingleProduct,
  Forward,
  PlainCheck,
  BrandCollaborations,
  BrandCollaborationsActive,
  Search,
  Logout,
  Share,
  MetapicLogo,
  ButtonAdd,
  ButtonChecked,
  ButtonDelete,
  ProductImagePlaceholder,
  TapGesture,
  Phone,
  Copy,
  CommissionPerClick,
  Convert,
  PlainRemove,
  Ratings,
  More,
  Expandable,
  NonExpandableMultiItems,
  NonExpandableSingleItem,
  PreviewEye,
  Home,
  HomeActive,
  TikTokBlack,
  CreditCard,
  Language,
  MoneyRound,
  Chart,
}

export type IconAssetsType = typeof IconAssets
export type IconAssetsKeys = keyof IconAssetsType

export default IconAssets

export const IconCatalogPaths: { [key in IconAssetsKeys]: string } = {
  RoundedCheck: roundedCheckSrc,
  RoundedOutlineCross: roundedOutlineCrossSrc,
  Info: infoSrc,
  InfoRegular: infoRegularSrc,
  InfoImportant: infoImportantSrc,
  RoundedClose: roundedCloseSrc,
  ZezamLogo: zezamLogoSrc,
  ZezamLogoOld: zezamLogoOldSrc,
  Close: closeSrc,
  Add: addSrc,
  Remove: removeSrc,
  ImageProfilePlaceholder: imageProfilePlaceholderSrc,
  UploadImage: uploadImageSrc,
  Audio: audioSrc,
  Chevron: chevronSrc,
  Rocket: rocketSrc,
  RocketActive: rocketActiveSrc,
  MyPage: myPageSrc,
  MyPageActive: myPageActiveSrc,
  Shop: shopSrc,
  ShopActive: shopActiveSrc,
  Menu: menuSrc,
  LogoFull: logoFullSrc,
  Paint: paintSrc,
  PaintActive: paintActiveSrc,
  Settings: settingsSrc,
  SettingsActive: settingsActiveSrc,
  AmazonMusic: AmazonMusicSrc,
  AppleMusic: AppleMusicSrc,
  Bandcamp: BandcampSrc,
  Beatport: BeatportSrc,
  ClubHouse: ClubHouseSrc,
  Deezer: DeezerSrc,
  Discord: DiscordSrc,
  Email: EmailSrc,
  Facebook: FacebookSrc,
  GitHub: GitHubSrc,
  GitLab: GitLabSrc,
  Instagram: InstagramSrc,
  LinkedIn: LinkedInSrc,
  Patreon: PatreonSrc,
  PayPal: PayPalSrc,
  Pinterest: PinterestSrc,
  Snapchat: SnapchatSrc,
  Soundcloud: SoundcloudSrc,
  Spotify: SpotifySrc,
  Telegram: TelegramSrc,
  TikTok: TikTokSrc,
  Twitch: TwitchSrc,
  Twitter: TwitterSrc,
  Vkontakte: VkontakteSrc,
  Vimeo: VimeoSrc,
  Website: WebsiteSrc,
  WhatsApp: WhatsAppSrc,
  YouTube: YouTubeSrc,
  Tumblr: TumblrSrc,
  Messenger: messengerSrc,
  AmazonMusicMono: amazonMusicMonoSrc,
  AppleMusicMono: appleMusicMonoSrc,
  BandcampMono: bandcampMonoSrc,
  BeatportMono: beatportMonoSrc,
  ClubhouseMono: clubhouseMonoSrc,
  DeezerMono: deezerMonoSrc,
  DiscordMono: discordMonoSrc,
  EmailMono: emailMonoSrc,
  FacebookMono: facebookMonoSrc,
  GithubMono: githubMonoSrc,
  GitlabMono: gitlabMonoSrc,
  InstagramMono: instagramMonoSrc,
  LinkedinMono: linkedinMonoSrc,
  PatreonMono: patreonMonoSrc,
  PaypalMono: paypalMonoSrc,
  PinterestMono: pinterestMonoSrc,
  SnapchatMono: snapchatMonoSrc,
  SoundcloudMono: soundcloudMonoSrc,
  SpotifyMono: spotifyMonoSrc,
  TelegramMono: telegramMonoSrc,
  TikTokMono: tikTokMonoSrc,
  TwitchMono: twitchMonoSrc,
  TwitterMono: twitterMonoSrc,
  VimeoMono: vimeoMonoSrc,
  VKontakteMono: vKontakteMonoSrc,
  WebsiteMono: websiteMonoSrc,
  WhatsappMono: whatsappMonoSrc,
  YoutubeMono: youtubeMonoSrc,
  TumblrMono: tumblrMonoSrc,
  ZezamMono: zezamMonoSrc,
  ApplePodcasts: ApplePodcastsSrc,
  ApplePodcastsMono: ApplePodcastsMonoSrc,
  Analytics: analyticsSrc,
  AnalyticsActive: analyticsActiveSrc,
  Calendar: calendarSrc,
  People: peopleSrc,
  Playlist: playlistSrc,
  CustomLink: customLinkSrc,
  PeopleGroup: peopleGroupSrc,
  Video: videoLinkSrc,
  Link: linkSrc,
  Pending: pendingSrc,
  MagicWand: magicWandSrc,
  Sparkles: sparklesSrc,
  SparkMono: sparkMonoSrc,
  Eyes: eyesSrc,
  ArrowBack: arrowBackSrc,
  Plus: plusSrc,
  PlusCircle: plusCircleSrc,
  UploadVideo: uploadVideoSrc,
  PasswordHide: passwordHideSrc,
  PasswordShow: passwordShowSrc,
  ZezamSpinner: zezamSpinnerSrc,
  Text: textSrc,
  InfoGrayScale: infoGrayScaleSrc,
  Message: messageSrc,
  DeleteBin: deleteBinSrc,
  RemoveCircleBlack: removeCircleBlackSrc,
  RemoveCircleWhite: removeCircleWhiteSrc,
  ArrowRight: arrowRightSrc,
  LinkExternal: linkExternalSrc,
  DragDots: dragDotsSrc,
  EditPen: editPenSrc,
  Download: downloadSrc,
  LocationPin: locationPinSrc,
  PriceLabel: priceLabelSrc,
  Time: timeSrc,
  LocationLivestream: locationLivestreamSrc,
  Redirect: redirectSrc,
  BgStyleSolid: bgStyleSolidSrc,
  BgStyleCover: bgStyleCoverSrc,
  BgStyleGradient: bgStyleGradientSrc,
  BgStyleHeader: bgStyleHeaderSrc,
  BgEffectNone: bgEffectNoneSrc,
  BgEffectFadedDark: bgEffectFadedDarkSrc,
  BgEffectFadedLight: bgEffectFadedLightSrc,
  BgEffectBlurredDark: bgEffectBlurredDarkSrc,
  BgEffectBlurredLight: bgEffectBlurredLightSrc,
  Gift: giftSrc,
  Referrals: referralsSrc,
  ReferralsActive: referralsActiveSrc,
  Undo: undoSrc,
  Apple: appleSrc,
  Google: googleSrc,
  Affiliate: affiliateSrc,
  Grid: gridSrc,
  DollarCircle: dollarCircleSrc,
  SingleProduct: singleProductSrc,
  Forward: forwardSrc,
  PlainCheck: plainCheckSrc,
  BrandCollaborations: brandCollaborationsSrc,
  BrandCollaborationsActive: brandCollaborationsActiveSrc,
  Search: searchSrc,
  Logout: logoutSrc,
  Share: shareSrc,
  MetapicLogo: metapicLogoSrc,
  ButtonAdd: buttonAddSrc,
  ButtonChecked: buttonCheckedSrc,
  ButtonDelete: buttonDeleteSrc,
  ProductImagePlaceholder: productImagePlaceholderSrc,
  TapGesture: tapGestureSrc,
  Phone: phoneSrc,
  Copy: copySrc,
  CommissionPerClick: commissionPerClickSrc,
  Convert: convertSrc,
  PlainRemove: plainRemoveSrc,
  Ratings: ratingsSrc,
  More: moreSrc,
  Expandable: expandableSrc,
  NonExpandableMultiItems: nonExpandableMultiItemsSrc,
  NonExpandableSingleItem: nonExpandableSingleItemSrc,
  PreviewEye: previewEyeSrc,
  Home: homeSrc,
  HomeActive: homeActiveSrc,
  TikTokBlack: tiktokBlackSrc,
  CreditCard: creditCardSrc,
  Language: languageSrc,
  MoneyRound: moneyRoundSrc,
  Chart: chartSrc,
}
